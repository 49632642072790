<template>
  <v-app>
    <v-main>
      <transition name="slide-x-transition" mode="out-in">
        <keep-alive include="KeepAlive">
          <router-view :key="$route.fullPath" />
        </keep-alive>
      </transition>
    </v-main>
    <p-toast
      v-model="toast.isShow"
      :msg="toast.msg"
      :timeout="toast.time"
      :isIc="toast.isIc"
      :callback="
        (isShow) => {
          toast.isShow = isShow;
        }
      "
      :style="{
        marginBottom: `${footerHeight}px`,
      }"
      :showClose="toast.showClose"
    />
    <v-dialog
      :persistent="pop.persistent"
      v-model="pop.isShow"
      :width="pxToRem(334)"
    >
      <p-base
        :title="typeof pop.title === 'undefined' ? '알림' : pop.title"
        :titleWeight="pop.msg?.trim() ? null : 'medium'"
        :okTxt="pop.okTxt"
        :cancelTxt="pop.cancelTxt"
        :onOk="
          () => {
            if (typeof pop.okAction !== 'undefined') {
              pop.okAction();
            }
            onClosePopupAction(pop);
          }
        "
        :onCancel="
          () => {
            if (typeof pop.cancelAction !== 'undefined') {
              pop.cancelAction();
            }
            onClosePopupAction(pop);
          }
        "
        :thema="
          typeof pop.thema === 'undefined' || !pop.thema ? 'default' : pop.thema
        "
      >
        <template v-for="(msg, index) in pop.msg?.split('\n')">
          {{ msg }}
          <br v-if="pop.msg?.split('\n').length - 1 > index" :key="index" />
        </template>
      </p-base>
    </v-dialog>
    <v-dialog
      :persistent="alert.persistent"
      v-model="alert.isShow"
      :width="pxToRem(334)"
    >
      <p-base
        class="pa-6"
        :title="null"
        :okTxt="alert.okTxt ? alert.okTxt : '확인'"
        :cancelTxt="null"
        :onOk="
          () => {
            if (typeof alert.okAction !== 'undefined') {
              alert.okAction();
            }
            onClosePopupAction(alert);
          }
        "
        :thema="typeof alert.thema === 'undefined' ? 'default' : alert.thema"
      >
        <span class="msg-alert" v-html="replaceLnToBr(alert.msg)" />
      </p-base>
    </v-dialog>
  </v-app>
</template>
<script>
import pToast from "./popup/pToast.vue";
export default {
  name: "App",
  components: { pToast },
  data() {
    return {
      toast: {
        isShow: false,
        msg: null,
        time: 1500,
        isIc: false,
        showClose: true,
      },
      pop: {
        isCreate: false,
        isShow: false,
        persistent: false,
        msg: null,
      },
      alert: {
        isCreate: false,
        isShow: false,
        persistent: false,
        msg: null,
      },
      footerHeight: 0,
    };
  },
  watch: {},
  created() {},
  mounted() {
    console.log('test')
    console.log("document.title : ", document.title)
    document.title = '어울림';
    window.backRouter = () => {
      var popupList = this.$store.state.popup.popupList;
      if (popupList && popupList.length > 0) {
        if (popupList[popupList.length - 1].route == this.$route.fullPath) {
          this.onClosePopupAction(popupList[popupList.length - 1]);
          return;
        }
      }
      if (
        !(
          window.backRouterAction &&
          typeof window.backRouterAction === "function" &&
          window.backRouterAction()
        )
      ) {
        this.$router.go(-1);
      }
    };
    window.pushRouter = (path) => {
      this.pushRouter({
        path: path,
      });
    };

    this.eventOn("SHOW_TOAST", (msg, isIc, showClose, time) => {
      if (this.$el.querySelector("footer")) {
        this.footerHeight = this.$el.querySelector("footer").offsetHeight;
      }
      this.toast.msg = msg;
      if (time) {
        this.toast.time = time;
      }
      if (isIc) {
        this.toast.isIc = isIc;
      }
      this.toast.showClose = showClose;
      this.toast.isShow = true;
      this.$forceUpdate();
    });
    this.eventOn("SHOW_POPUP", (msg) => {
      this.pop = {};
      if (typeof msg == "object") {
        this.pop.msg = msg.msg;
        this.pop.title = msg.title;
        this.pop.okTxt = msg.okTxt;
        this.pop.cancelTxt = msg.cancelTxt;
        this.pop.okAction = msg.okAction;
        this.pop.cancelAction = msg.cancelAction;
        if (typeof msg.persistent != "undefined" && msg.persistent != null) {
          this.pop.persistent = msg.persistent;
        }
        if (msg.thema) {
          this.pop.thema = msg.thema;
        }
      } else if (typeof msg == "string") {
        this.pop = {
          isCreate: false,
          isShow: false,
          msg: msg,
        };
      }
      this.$forceUpdate();
      this.onShowPopupAction(this.pop);
    });
    this.eventOn("SHOW_ALERT", (msg) => {
      this.alert = {};
      if (typeof msg == "object") {
        this.alert.msg = msg.msg;
        this.alert.okTxt = msg.okTxt;
        this.alert.okAction = msg.okAction;

        if (typeof msg.persistent != "undefined" && msg.persistent != null) {
          this.alert.persistent = msg.persistent;
        }
        if (msg.thema) {
          this.alert.thema = msg.thema;
        }
      } else if (typeof msg == "string") {
        this.alert = {
          isCreate: false,
          isShow: false,
          msg: msg,
        };
      }
      this.$forceUpdate();
      this.onShowPopupAction(this.alert);
    });
  },
  activated() {},
  deactivated() {},
  destroyed() {
    delete window.backRouter;
    delete window.pushRouter;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/sass/app.scss";
.msg-alert {
  font-weight: 500;
  font-size: pxToRem(18px);
  line-height: pxToRem(24px);
  text-align: center;
  letter-spacing: -0.004em;
  color: #1a1a1a;
  word-break: break-all;
}
</style>
