export const baseRemSize = 16

export const SERVER_URL = 'https://aulim.keris.or.kr'

export const API_URL = SERVER_URL + '/api'
export const API_COMMON_ERROR_MSG = '네트워크 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.'

const getAgentData = (key) => {
    let parserKey = `,${key}:`;
    let retData = "";
    if (navigator.userAgent.search(parserKey) != -1) {
        retData = navigator.userAgent.substring(
            navigator.userAgent.indexOf(parserKey) + parserKey.length
        );
        retData = retData.substring(0, retData.indexOf(","));
    }
    return retData;
}